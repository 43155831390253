import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

// Material UI components
import AppBar from "@mui/material/AppBar";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// Actions
import { userActions } from "../../../store/actions/userActions";
// import { userService } from "../../../store/services/userService";
import { globalAlertService } from "../../../store/services/shared/globalAlertService";
import { verimiAdminService } from "../../../store/services/admin/verimiAdminService";
import { notificationActions } from "../../../store/actions/notification/notificationActions";
import { vorgangeListActions } from "../../../store/actions/vorgange/vorgangeListAction";

// Assets
import profileLogo from "../../../assets/images/icon_profil.svg";
import carLogo from "../../../assets/images/my_digital_car.svg";
import DownloadFileIcon from "../../iconComponents/DownloadFileIcon";
import RightArrowIcon from "../../iconComponents/RightArrowIcon";
import NotificationBellIcon from "../../iconComponents/NotificationBellIcon";

// Components
import GlobalAlert from "../../shared/GlobalAlert";
import NotificationDialog from "./NotificationDialog";
import MessageDetailsPopupDialog from "./MessageDetailsPopupDialog";

import MDCBadge from "../../core/MDCBadge";
import NotificationMessage from "../../core/NotificationMessage";
import StatusServiceMessages from "./StatusServiceMessages";

//Constants
import {
  EMP_ID_MISSING_ERROR,
  BANK_INFO_MISSING_ERROR,
  TENANT_INFO_MISSING_ERROR,
} from "../../../constants/messageConstants";
import {
  USER_ROLE_ADMIN,
  USER_ROLE_MDC_ADMIN,
  USER_ROLE_SUPPORT,
  USER_ROLE_API_USER,
} from "../../../constants/userConstants";

import {
  HIST_NOTIFICATIONS,
  READ_NOTIFICATIONS,
  NEW_NOTIFICATION,
  MESSAGE,
} from "../../../constants/notificationConstants";

// Helper
import { history } from "../../../helpers/history";
import { getFileExtension } from "../../../helpers/fileExtension";
import Container from "@mui/material/Container";
import { config } from "../../../store/services/config";

// Theme
import theme from "../../../themes/mdc-theme";

const styles = (theme) => ({
  root: {
    "&.MuiPaper-root": { backgroundColor: "initial" },
    fontFamily: theme.palette.typography.fontFamily,
  },
  profileList: {
    "& ul[role='menu']": {
      flexDirection: "column",
    },
    "& .MuiMenuItem-root": {
      fontFamily: theme.palette.typography.fontFamily,
    },
  },
  downloadsStyles: {
    "& .MuiPaper-root": {
      left: "76% !important",
      top: "19% !important",
    },
  },
  spanStyles: {
    position: "relative",
    top: "0px",
    right: "25px",
    backgroundColor: theme.palette.MDCColors.color23,
    width: "27px",
    height: "27px",
    borderRadius: "100%",
    color: "white",
    textAlign: "center",
  },
  administrationLabel: {
    color: theme.palette.MDCColors.color23,
    fontSize: "2rem",
    marginLeft: "10%",
    alignSelf: "flex-end",
    fontWeight: "bold",
    marginTop: "1rem",
  },
  dashboardLink: {
    paddingTop: "14px",
    cursor: "pointer",
    color: theme.palette.MDCColors.color23,
    fontSize: "1.125rem",
    fontWeight: "600",
    textDecoration: "underline",
    paddingLeft: "4rem",
    "& a": {
      color: theme.palette.MDCColors.textColor,
    },
  },
  imgFluid: {
    maxWidth: "100%",
    height: "auto",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  downloadFileIconStyles: {
    color: theme.palette.MDCColors.color23,
    marginRight: "1rem",
  },
  headerBackground: { backgroundColor: theme.palette.MDCColors.white },
});

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
    this.state = {
      lastUpdated: "10:30",
      filePreview: "",
      fileName: "",
      showNotificationDialog: false,
      data: [],
      anchorProfile: null,
      notificationsData: [],
      openDownloads: false,
      groupStatusMessages: [],
      showMessageDetail: false,
      messageDetails: "",
    };
  }

  componentDidMount = () => {
    // userService.startRefreshTokenTimer();
    const aliasName =
      history?.location?.pathname === "/tenant"
        ? history?.location?.state?.aliasName
        : this.props?.user?.tenant_alias;

    if (aliasName) {
      this.checkForGlobalError(aliasName);
      this.getNotifications(aliasName);
    }

    if (aliasName && !this.props?.user?.roles?.includes(USER_ROLE_MDC_ADMIN)) {
      this.props
        .getGroupStatusMessages(
          this.props?.user?.group_id,
          this.props?.user?.tenant_alias
        )
        .then((resp) => {
          if (resp?.status === 200 && resp?.data) {
            this.setState({ groupStatusMessages: resp.data });
          }
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user !== prevProps.user) {
      this.checkForGlobalError(
        history?.location?.pathname === "/tenant"
          ? history?.location?.state?.aliasName
          : this.props?.user?.tenant_alias
      );
    }
    if (this.state.notificationsData !== prevState.notificationsData) {
      if (this.state.notificationsData?.length === 0) {
        this.setState({ showNotificationDialog: false });
      }
    }
  }

  handleClickProfile = (event) => {
    this.setState({ anchorProfile: event.currentTarget, openDownloads: false });
  };
  handleCloseProfile = () => {
    this.setState({ anchorProfile: null });
  };
  handleClickDownloads = () => {
    this.setState({
      openDownloads: true,
    });
  };

  handleCloseDownloads = () => {
    this.setState({ openDownloads: false });
  };

  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem("selectedRowsKey");
    localStorage.clear();
    this.props.logout("/login");
  }

  // Get File Name For Document
  getFileNameFromHttpResponse = (httpResponse) => {
    return (httpResponse && httpResponse.headers["x-suggested-filename"]) || "";
  };

  handleBlobData = (fileData, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([fileData], { type: "application/pdf" })
    );

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  downloadTemplate = (docType) => {
    this.setState({ anchorProfile: null });
    let fileName, fileData;
    switch (docType) {
      case "excel":
        this.props.getExcelTempalte().then(() => {
          if (this.props?.excelTempalte?.status === 200) {
            fileName = this.getFileNameFromHttpResponse(
              this.props?.excelTempalte
            );
            fileData = this.props?.excelTempalte?.data;
            this.handleBlobData(fileData, fileName);
          }
        });
        break;
      case "Dauervollmacht":
        this.props.getTempaltePDF("dauervollmacht").then(() => {
          fileData = this.props?.tempaltePDF?.data;
          fileName = this.getFileNameFromHttpResponse(this.props?.tempaltePDF);
          this.handleBlobData(fileData, fileName);
        });

        break;
      case "SEPA":
        this.props.getTempaltePDF("sepa-mandate").then(() => {
          if (this.props?.tempaltePDF?.status === 200) {
            fileName = this.getFileNameFromHttpResponse(
              this.props?.tempaltePDF
            );
            fileData = this.props?.tempaltePDF?.data;
            this.handleBlobData(fileData, fileName);
          }
        });
        break;
    }
  };

  toastNotification = (data) => {
    if (data.messageType === "error") {
      toast.error(
        <NotificationMessage
          requestId={data?.requestId}
          primaryReference={data?.primaryReference}
          message={data?.message}
        />
      );
    } else {
      toast.success(
        <NotificationMessage
          requestId={data?.requestId}
          primaryReference={data?.primaryReference}
          message={data?.message}
        />
      );
    }
  };

  refreshTable = (notificationData) => {
    // Notify dashboard table component(VorgangList) that web socket notification is triggered for Einreichen request
    this.props.isDashboardTableEinreichenNotification(true, notificationData);
    // Refresh table once record is processed
    this.props.getVorgangeList(this.props.dashboardTableQuery, "insgesamt");
  };

  toastNotificationAndRefreshTable = (vorgangNotifications) => {
    this.toastNotification(vorgangNotifications);
    this.refreshTable(vorgangNotifications);
  };

  getNotifications = (aliasName) => {
    if (aliasName) {
      this.props.getTokenForWebsocket().then((resp) => {
        if (resp?.data) {
          const protocol =
            config?.apiUrl?.split(":")[0] === "http" ? "ws" : "wss";

          const host = new URL(config.apiUrl).host;
          const token = resp.data;
          let socket = new WebSocket(
            `${protocol}://${host}/notifications/ws?token=${token}`
          );
          if (socket) {
            socket.onmessage = (event) => {
              if (event?.data) {
                let vorgangNotificationsData = JSON.parse(event?.data);
                const vorgangNotifications = vorgangNotificationsData?.data;

                // History notifications data received only first time socket connection is established
                if (vorgangNotificationsData?.type === HIST_NOTIFICATIONS) {
                  this.setState({
                    notificationsData: vorgangNotifications,
                  });
                  // Read only notification data
                } else if (
                  vorgangNotificationsData?.type === READ_NOTIFICATIONS
                ) {
                  this.setState({
                    notificationsData: this.state.notificationsData?.filter(
                      (item) => !vorgangNotifications?.includes(item.messageId)
                    ),
                  });
                  // Will be received for success or error case
                } else if (
                  vorgangNotificationsData?.type === NEW_NOTIFICATION
                ) {
                  this.setState({
                    notificationsData: [
                      ...this.state?.notificationsData,
                      vorgangNotifications,
                    ],
                  });
                  this.toastNotificationAndRefreshTable(vorgangNotifications);
                  // Basic messages received from notification service
                } else if (vorgangNotificationsData?.type === MESSAGE) {
                  this.toastNotificationAndRefreshTable(vorgangNotifications);
                }
              }
            };
            //keep socket connection alive
            setInterval(() => {
              socket?.send("Keep-Alive");
            }, 55000);
          }
        }
      });
    }
  };

  getUpdatedLogo = async (aliasName) => {
    if (aliasName) {
      const logo = await verimiAdminService.getDetail(aliasName, "logo");
      if (logo && logo.file && !logo.isAxiosError) {
        const fileExtension = getFileExtension(logo?.fileName);
        //If image is of svg type then we are adding type: "image/svg+xml" for proper Blob URL object"
        const imageBlobData =
          fileExtension === "svg"
            ? new Blob([logo.file], {
                type: "image/svg+xml",
              })
            : logo.file;

        this.setState({
          fileName: logo?.fileName,
          filePreview: URL.createObjectURL(imageBlobData),
        });
      }
    }
  };

  checkForGlobalError = async (aliasName) => {
    let errorMessage;

    if (aliasName) {
      await this.getUpdatedLogo(aliasName);
      await this.props.getCompanyDetails(aliasName);
    }

    let bankInfoMissing = !this.props.companyDetails?.accountPresent;
    let gksDetailsMissing = !this.props.companyDetails?.gksDetailsPresent;

    let autoClose = false;
    let closeIcon = false;
    let keepAfterRouteChange = false;
    let empIdMissing = this.props?.user?.employeeId ? false : true;
    if (empIdMissing || bankInfoMissing || gksDetailsMissing) {
      globalAlertService.clear();
      if (empIdMissing) {
        errorMessage = EMP_ID_MISSING_ERROR;
        globalAlertService.error(errorMessage, {
          autoClose,
          keepAfterRouteChange,
          closeIcon,
          forwardLink: "/profile",
        });
      } else {
        globalAlertService.clear();
      }

      const isAdmin = this.props?.user?.roles?.includes(USER_ROLE_ADMIN);
      const isAPIUser = this.props?.user?.roles?.includes(USER_ROLE_API_USER);

      if (bankInfoMissing || gksDetailsMissing) {
        errorMessage = BANK_INFO_MISSING_ERROR;
        const options = {
          autoClose,
          keepAfterRouteChange,
          closeIcon,
        };
        if (isAdmin || isAPIUser) {
          errorMessage = TENANT_INFO_MISSING_ERROR;
          options.forwardLink = "/admin";
        }
        setTimeout(() => {
          globalAlertService.error(errorMessage, options);
        }, 1);
      }
    } else {
      globalAlertService.clear();
    }
  };

  handleNotification = () => {
    this.setState({
      showNotificationDialog: false,
    });
  };

  showMessageDetailDialog = (messageDetails) => {
    this.setState({ showMessageDetail: true, messageDetails: messageDetails });
  };

  render() {
    const { showNotificationDialog, anchorProfile, openDownloads } = this.state;

    const pathName = history?.location?.pathname;

    const isAppDashboard = pathName === "/app-dashboard";

    const isSupportUser = this.props?.user?.roles?.includes(USER_ROLE_SUPPORT);
    const isAPIUser = this.props?.user?.roles?.includes(USER_ROLE_API_USER);

    const isMDCAdmin = this.props?.user?.roles?.includes(USER_ROLE_MDC_ADMIN);

    const isAdmin = this.props?.user?.roles?.includes(USER_ROLE_ADMIN);
    return (
      (!this.props.authentication?.user || pathName !== "/register-popup") && (
        <>
          <div className={this.props?.classes?.headerBackground}>
            <Container maxWidth="xl">
              <div>
                <AppBar
                  position="static"
                  className={this.props?.classes?.root}
                  style={{ background: "transparent", boxShadow: "none" }}
                >
                  <Grid container spacing={2} paddingTop="4px">
                    <Grid item xs={5}>
                      <Grid container spacing={3} alignItems={"center"}>
                        <Grid
                          marginTop="1rem"
                          marginBottom="0.5rem"
                          item
                          xs={7}
                        >
                          {this.props.authentication?.loggedIn && (
                            <a href="/">
                              <img alt="My Digital Car" src={carLogo} />
                            </a>
                          )}
                        </Grid>
                        {pathName === "/app-dashboard" && (
                          <Grid item xs={5}>
                            <label
                              className={
                                this.props?.classes?.administrationLabel
                              }
                            >
                              Administration
                            </label>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid
                        item
                        marginTop="1rem"
                        marginBottom="0.5rem"
                        height="5rem"
                        display="flex"
                        justifyContent="space-around"
                      >
                        {this.state.filePreview.length ? (
                          <img
                            className={this.props?.classes?.imgFluid}
                            src={this.state.filePreview}
                            alt={this.state.fileName}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid item xs={5}>
                      <Grid
                        container
                        height="120%"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        {!isAppDashboard &&
                          !this.props?.user?.roles?.includes(
                            USER_ROLE_SUPPORT
                          ) &&
                          this.props?.user?.preferred_username && (
                            <Grid item xs={9}>
                              <GlobalAlert
                                handleNotification={this.handleNotification}
                                certificateDetails={
                                  this.props?.companyDetails?.accountPresent &&
                                  this.props?.companyDetails?.gksDetailsPresent
                                }
                                userAuthority={this.props?.user?.roles}
                              />
                            </Grid>
                          )}
                        {this.props.authentication?.loggedIn && (
                          <Grid
                            item
                            xs={3}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                          >
                            <Grid item marginRight="1rem">
                              <img
                                src={profileLogo}
                                alt="user pic"
                                onClick={this.handleClickProfile}
                                className={this.props?.classes?.cursorPointer}
                              />
                              <Menu
                                className={this.props?.classes?.profileList}
                                anchorEl={anchorProfile}
                                open={Boolean(anchorProfile)}
                                onClose={this.handleCloseProfile}
                                PaperProps={{
                                  style: {
                                    left: "97.188rem",
                                    transform:
                                      "translateX(-40%) translateY(58%)",
                                  },
                                }}
                              >
                                <MenuItem component={Link} to="/profile">
                                  Nutzerprofil
                                </MenuItem>
                                {((this.props.user && isMDCAdmin) ||
                                  isAdmin ||
                                  isAPIUser ||
                                  isSupportUser) && (
                                  <MenuItem component={Link} to="/admin">
                                    Mandantenprofil
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={() => this.handleClickDownloads()}
                                >
                                  <Grid
                                    container
                                    justifyContent="space-between"
                                  >
                                    <Grid item>Downloads</Grid>
                                    <Grid item>
                                      <RightArrowIcon />
                                    </Grid>
                                  </Grid>
                                </MenuItem>

                                <Menu
                                  className={
                                    this.props?.classes?.downloadsStyles
                                  }
                                  anchorEl={openDownloads}
                                  open={Boolean(openDownloads)}
                                  onClose={this.handleCloseDownloads}
                                  anchorOrigin={{ horizontal: "right" }}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      this.downloadTemplate("excel")
                                    }
                                  >
                                    <DownloadFileIcon
                                      className={
                                        this.props?.classes
                                          ?.downloadFileIconStyles
                                      }
                                    />
                                    Sammelantrag (CSV)
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      this.downloadTemplate("Dauervollmacht")
                                    }
                                  >
                                    <DownloadFileIcon
                                      className={
                                        this.props?.classes
                                          ?.downloadFileIconStyles
                                      }
                                    />
                                    Dateiupload-Template Dauervollmacht
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      this.downloadTemplate("SEPA")
                                    }
                                  >
                                    <DownloadFileIcon
                                      className={
                                        this.props?.classes
                                          ?.downloadFileIconStyles
                                      }
                                    />
                                    Dateiupload-Template SEPA-Mandat
                                  </MenuItem>
                                </Menu>

                                {this.props.user && isMDCAdmin && (
                                  <MenuItem
                                    component={Link}
                                    to="/broadcastMessage"
                                  >
                                    Nachrichten
                                  </MenuItem>
                                )}
                                {!isMDCAdmin && (
                                  <MenuItem
                                    component={Link}
                                    to="/vollmachtsdatenbank"
                                  >
                                    Vollmachtsdatenbank
                                  </MenuItem>
                                )}
                                <MenuItem onClick={this.onLogout}>
                                  Abmelden
                                </MenuItem>
                              </Menu>
                            </Grid>
                            <Grid
                              item
                              display="flex"
                              flexDirection="unset"
                              position="relative"
                              top="0.75rem"
                              onClick={() => {
                                if (
                                  !this.state?.notificationsData?.length ||
                                  this.state?.notificationsData?.length === 0
                                ) {
                                  return false;
                                }
                                this.setState({
                                  showNotificationDialog:
                                    !showNotificationDialog,
                                });
                              }}
                            >
                              <MDCBadge
                                badgeContent={
                                  this.state?.notificationsData?.length || 0
                                }
                                icon={
                                  <NotificationBellIcon
                                    color={theme.palette.MDCColors.color18}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                        {showNotificationDialog && (
                          <Grid
                            item
                            position="absolute"
                            top="10%"
                            width="35.875rem"
                            zIndex={1}
                            paddingTop="0px !important"
                            marginTop="24px"
                          >
                            <NotificationDialog
                              notificationsData={this.state.notificationsData}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </AppBar>
              </div>
            </Container>
          </div>

          {this.state.groupStatusMessages && (
            <StatusServiceMessages
              statusServiceData={this.state.groupStatusMessages}
              showMessageDetailDialog={this.showMessageDetailDialog}
            />
          )}
          {this.state.showMessageDetail && this.state.messageDetails && (
            <MessageDetailsPopupDialog
              show={this.state.showMessageDetail}
              handlePopupClose={() => {
                this.setState({
                  showMessageDetail: false,
                });
              }}
              messageDetails={this.state.messageDetails}
            />
          )}
        </>
      )
    );
  }
}

function mapState(state) {
  return {
    authentication: state.authentication,
    user: state.authentication?.user,
    companyDetails: state?.authentication?.companyDetails,
    excelTempalte: state.authentication.excelTempalte,
    tempaltePDF: state.authentication.tempaltePDF,
    dashboardTableQuery: state.vorgangeListReducer?.dashboardTableQuery,
  };
}

const actionCreators = {
  logout: userActions.logout,
  getTokenForWebsocket: notificationActions.getTokenForWebsocket,
  getGroupStatusMessages: notificationActions.getGroupStatusMessages,
  getCompanyDetails: userActions.getCompanyDetails,
  getExcelTempalte: userActions.getExcelTempalte,
  getTempaltePDF: userActions.getTempaltePDF,
  getVorgangeList: vorgangeListActions.getVorgangeList,
  isDashboardTableEinreichenNotification:
    vorgangeListActions.isDashboardTableEinreichenNotification,
};

export default connect(mapState, actionCreators)(withStyles(styles)(Header));
