export const deadLetterQueueConstants = {
  DEAD_LETTER_QUEUE_STATUS_ARR: {
    Abgelehnt: "Abgelehnt",
    Genehmigt: "Genehmigt",
  },
  GET_DEAD_LETTER_QUEUE_LIST: "GET_DEAD_LETTER_QUEUE_LIST",
  GET_DEAD_LETTER_QUEUE_XML_SUCCESS: "GET_DEAD_LETTER_QUEUE_XML_SUCCESS",
  GET_UNMATCHED_DEAD_LETTER_QUEUE_LIST: "GET_UNMATCHED_DEAD_LETTER_QUEUE_LIST",
  GET_UNMATCHED_DEAD_LETTER_QUEUE_XML_SUCCESS:
    "GET_UNMATCHED_DEAD_LETTER_QUEUE_XML_SUCCESS",
};
